<div class="ui grid">
  <div class="two wide column"></div>
  <div class="twelve wide column">
    <h1 class="ui header">FIT-Datenschutzerklärung</h1>

    <h3>Informationen nach Art. 13 DSGVO</h3>
    <h3>anlässlich der Datenerhebung bei der Teilnahme am FIT-Netzwerk und des Abonnements des Newsletters „FIT für die
      Wissenschaft“ der Forschungs- und Graduiertenförderung der Universität Kassel</h3>
    <p>
      <b>Verantwortlich im datenschutzrechtlichen Sinne:</b> <br>
      Universität Kassel<br>
      Die Präsidentin<br>
      34109 Kassel<br>
      Telefon: +49-561-804-0<br>
      Web: www.uni-kassel.de<br>
      E-Mail: praesidentin&#64;uni-kassel.de<br>
    </p>

    <p>
      <b>Kontaktdaten der Datenschutzbeauftragten:</b><br>
      Die Datenschutzbeauftragte<br>
      Universität Kassel<br>
      34109 Kassel<br>
      Telefon: +49-561-804-7036/-2011<br>
      Web: www.uni-kassel.de/go/datenschutz<br>
      E-Mail: datenschutz&#64;uni-kassel.de<br>
    </p>

    <p>
      <b>Kontaktdaten des verantwortlichen Instituts und Ansprechpartners:</b><br>
      Universität Kassel<br>
      Forschungs- und Graduiertenförderung – Koordinationsteam FIT<br>
      Roswitha Schwarz / Carolin Schwarz<br>
      34109 Kassel<br>
      Telefon: +49-561-804-2031/-2922<br>
    </p>

    <p>
      <b>Zwecke der Verarbeitung</b><br>
      Die Verarbeitung Ihrer personenbezogenen Daten erfolgt zur Teilnahme am FIT-Netzwerk bzw. zum Bezug des
      Forschungsförderungs-Newsletters „FIT für die Wissenschaft“ der Universität Kassel. Weitere Informationen zum
      FIT-Netzwerk erhalten Sie unter www.uni-kassel.de/go/fit.
    </p>

    <p>
      <b>Rechtsgrundlage für die Verarbeitung</b><br>
      Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten ist Ihre Einwilligung gemäß Art. 6 Abs. 1
      UAbs.1
      a) DSGVO. Sofern besondere Kategorien personenbezogener Daten betroffen sind, werden die von uns erhobenen Daten
      auf
      Basis Ihrer ausdrücklichen Einwilligung gemäß Art. 9 Abs. 2 a) DSGVO verarbeitet.
    </p>

    <p>
      <b>Kategorien personenbezogener Daten, die verarbeitet werden:</b><br>
      <u>Kontaktdaten:</u><br>
    </p>
    <ul>
      <li>Name</li>
      <li>dienstliche E-Mail-Adresse</li>
      <li>Titel</li>
      <li>Statusgruppe (Wissenschaftl. Mitarbeiter/in, Professor/in, Doktorand/in, Promovierte, Student/in, Sonstige/r
        Mitarbeiter/in), Institution, Abteilung)
      </li>
    </ul>
    <p>
      <u>demografische Daten:</u><br>
    </p>
    <ul>
      <li>Geschlecht</li>
    </ul>

    <p>
      <b>Empfänger oder Kategorien von Empfängern/Drittstaatenübermittlung</b><br>
      Die Statusgruppe, die Institution sowie die Abteilung wird im Rahmen einer anonymen Statistik für die „Subadmins“
      (s.u.) aller Institutionen abgebildet. Bei Institutionen mit wenigen Abonnent*innen kann daraus gegebenenfalls ein
      Rückschluss auf Ihre Person möglich sein.
    </p>
    <p>
      „Subadmins“ sind User mit erweiterten Benutzerrechten. In der Regel sind dies Forschungsreferent*innen oder
      Mitarbeiter*innen von Forschungsunterstützungsabteilungen an Universitäten, (Fach-)Hochschulen und
      außeruniversitären Forschungseinrichtungen. Jede an FIT teilnehmende Organisation benennt bei Abschluss eines
      Mitgliedschaftsvertrags mindestens 2-3 Personen, die diese erweiterten Benutzerrechte erhalten. Subadmins können
      anonyme Statistiken darüber abrufen wie viele Mitglieder der eigenen Institution in FIT registriert sind,
      aufgeschlüsselt nach Abteilungen/Fachbereichen oder nach Statusgruppe.
    </p>
    <p>
      Darüberhinausgehende personenbezogene Daten werden nicht an Dritte weitergegeben.
    </p>

    <p>
      <b>Dauer der Speicherung der personenbezogenen Daten/Kriterien für die Festlegung der Dauer</b><br>
      Ihre Daten werden auf unbestimmte Zeit gespeichert, so lange Sie den FIT-Newsletter beziehen. Eine maximale
      Nutzungsdauer ist nicht festgelegt. Nach Beendigung der Mitgliedschaft Ihrer Institution im FIT-Netzwerk oder nach
      Abmeldung von Usern vom FIT-Newsletter werden sämtliche personenbezogenen Daten sowie auch personalisierte
      Einstellungen zum Newsletter-Empfang gelöscht.
    </p>

    <p>
      <b>Keine automatisierte Entscheidungsfindung (inklusive Profiling)</b><br>
      Eine Verarbeitung Ihrer personenbezogenen Daten zum Zwecke einer automatisierten Entscheidungsfindung
      (einschließlich Profiling) gemäß Art. 22 Abs. 1 DSGVO findet nicht statt.
    </p>

    <p>
      <b>Widerrufsrecht bei Einwilligung</b><br>
      Sie haben das Recht, Ihre Einwilligung jederzeit gegenüber dem genannten Ansprechpartner für die Zukunft zu
      widerrufen. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung wird
      durch
      diesen nicht berührt.
    </p>

    <p>
      <b>Ihre weiteren Rechte</b><br>
      Gegenüber dem genannten Ansprechpartner oder unseren Datenschutzbeauftragten können Sie jederzeit folgende Rechte
      ausüben:
    </p>
    <ul>
      <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,</li>
      <li>Berichtigung unrichtiger personenbezogener Daten,</li>
      <li>Löschen der Sie betreffenden personenbezogenen Daten, sofern die Voraussetzungen für eine Löschung gegeben
        sind,
      </li>
      <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns.</li>
    </ul>

    <p>
      <b>Beschwerderecht</b><br>
      Schließlich haben Sie das Recht, sich bei datenschutzrechtlichen Problemen bei der zuständigen
      Fachaufsichtsbehörde zu beschweren.<br>
      Kontaktadresse der Fachaufsichtsbehörde der Universität Kassel:<br>
      Der Hessische Beauftragte für Datenschutz und Informationsfreiheit (HBDI)<br>
      Postfach 3163<br>
      65021 Wiesbaden<br>
      E-Mail: poststelle&#64;datenschutz.hessen.de<br>
      Telefon: +49-611-1408-0<br>
    </p>
  </div>
  <div class="two wide column"></div>
</div>
